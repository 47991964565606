import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { UsersService } from '../../services/users/users.service';
import { AppUserService } from '../../services/appUser/app-user.service';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-app-user-add',
  templateUrl: './app-user-add.component.html',
  styleUrls: ['./app-user-add.component.css']
})
export class AppUserAddComponent implements OnInit {

  userForm: FormGroup;
  first_name: string = '';
  last_name: string = '';
  username: string = '';
  password: string = '';
  mobile_no: number = null;
  email_id: string = '';
  address: string = '';
  qualification: string = '';
  is_active: string = '';
  is_locked: string = '';
  user_type_id: string = '';

  isLoadingResults = false;

  isUsernameExist = true;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private appUserService: AppUserService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      'first_name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'last_name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      //'username': [null, [Validators.required, Validators.minLength(4), this.isUsernameUnique.bind(this)]],
      'username': [null, [Validators.required, Validators.minLength(4)]],
      'mobile_no': [null, Validators.required],
      'email_id': [null, Validators.required],
      'password': [null, Validators.required],
      'address': [null, Validators.required],
      'qualification': [null, Validators.required]
    //},{ updateOn: 'blur' });
    });

    console.log(this.userForm);
  }

  
  /*
  isUsernameUnique(control: FormControl){
    const q = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.appUserService.isUserNameRegisterd(control.value).subscribe(() => {
          //resolve(null);
          console.log(resolve);
        }, () => { resolve({ 'isEmailUnique': true }); });
      }, 1000);
    });
    //console.log(q);
    return q;
  }
  */

  
  isUsernameUnique(control: FormControl) {
    this.appUserService.isUserNameRegisterd(control.value).subscribe(res => {
      this.isUsernameExist = res;
      //return ({ 'isEmailUnique': true }); 
      //resolve(null);
      //console.log(res);
    })
  }
  

  onFormSubmit(form: NgForm) {
    this.isLoadingResults = true;
    this.appUserService.addUser(form)
      .subscribe(res => {
        //let id = res['id'];
        this.isLoadingResults = false;
        //this.router.navigate(['/product-details', id]);
        this.router.navigate(['/users']);
        //location.href = "/users";
        //this.router.navigateByUrl('users');
        //this.router.navigate([ 'users' ], { relativeTo: this.route })
      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

}

