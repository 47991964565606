import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExamsService } from 'src/app/services/exams/exams.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-new-pre-exam-date',
  templateUrl: './edit-new-pre-exam-date.component.html',
  styleUrls: ['./edit-new-pre-exam-date.component.css']
})
export class EditNewPreExamDateComponent implements OnInit {

  categoryID: any;
  categoryName: any;
  preExamID: any;

  editNewPreExamDateForm: FormGroup;
  editPreExamDateDataFromServer: any;

  isLoadingResults = false;

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private route: ActivatedRoute, private router: Router) {
    this.categoryID = this.route.snapshot.params['categoryID'];
    this.categoryName = this.route.snapshot.params['categoryName'];
    this.preExamID = this.route.snapshot.params['preExamID'];

    this.editNewPreExamDateForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'name': [null, [Validators.required]],
      'sub_name': [null, [Validators.required]],
      'exam_date': [null, [Validators.required]],
      'start_date': [null, Validators.required],
      'end_date': [null, Validators.required],
      'is_active': [null, Validators.required]
    });

    this.getNewPreExamDateDetails(this.categoryID, this.preExamID);

  }


  getNewPreExamDateDetails(categoryID, preExamID) {
    this.examsService.getNewPreExamDateDetails(categoryID, preExamID)
      .subscribe(res => {
        if (res) {
          this.editPreExamDateDataFromServer = res[0];
          console.log(res);
          this.editNewPreExamDateForm.setValue({
            //category_id: parseInt(this.editPreExamDateDataFromServer.category_id),
            name: this.editPreExamDateDataFromServer.name,
            sub_name: this.editPreExamDateDataFromServer.sub_name,
            exam_date: this.editPreExamDateDataFromServer.date,
            start_date: this.editPreExamDateDataFromServer.start_date,
            end_date: this.editPreExamDateDataFromServer.end_date,
            is_active: this.editPreExamDateDataFromServer.is_active
          });
        }
        else {
          console.log(res);
        }
      }), (err) => {
      }
  }

  onFormSubmit(form){
    if (form.is_active == "-1") {
      alert("please select is active");
    }
    else {
      this.isLoadingResults = true;
      form.user_id = localStorage.getItem('userID');
      form.preExamID = this.preExamID;
      form.categoryID = this.categoryID;
      
      console.log(form);
      
      this.examsService.editNewPreExamDate(form)
        .subscribe(res => {
         if(res == 1){
          this.isLoadingResults = false;
          alert("update suessfully done !");
          this.router.navigate(['/preExam/addPreExam']);
         }
         else{
          this.isLoadingResults = false;
          alert("server error");
          //this.router.navigate(['/preExam/addPreExam']);
         }
          //let id = res['id'];
         
        }, (err) => {
          console.log(err);
          this.isLoadingResults = false;
        });
    }
  }
  ngOnInit() {
  }

}
