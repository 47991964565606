import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ExamsService } from '../../services/exams/exams.service';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-edit-daily-news',
  templateUrl: './edit-daily-news.component.html',
  styleUrls: ['./edit-daily-news.component.css']
})
export class EditDailyNewsComponent implements OnInit {

  editNewsAndUpdateForm: FormGroup;

  editNewsAndUpdateDataFromServer: any;

  newsAndUpdateID: any;

  isLoadingResults = false;

  categoryList: string[];

  newsID: any;
  categoryID: any;

  selectedGame = 1; 

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private route: ActivatedRoute, private router: Router) {

    this.getExamCategory();

    //this.examSubItemId = this.route.snapshot.params['id'];

    this.newsID = this.route.snapshot.params['id1'];
    this.categoryID = this.route.snapshot.params['id2'];

    this.getNewsDetails(this.newsID, this.categoryID);

    this.editNewsAndUpdateForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category_id': [null, [Validators.required, Validators.min(1)]],
      'title': [null, [Validators.required]],
      'body': [null, [Validators.required]],
      'sub_body': [null],
      'editor_by': [null],
      'place': [null],
      'notes': [null]
    });
  }

  getNewsDetails(id, categoryID) {
    this.examsService.getNewsAndUpdateForEdit(id, categoryID)
      .subscribe(res => {
        if (res) {
          this.editNewsAndUpdateDataFromServer = res[0];
          console.log(res);
          this.editNewsAndUpdateForm.setValue({
            category_id: parseInt(this.editNewsAndUpdateDataFromServer.category_id),
            title: this.editNewsAndUpdateDataFromServer.title,
            body: this.editNewsAndUpdateDataFromServer.body,
            sub_body: this.editNewsAndUpdateDataFromServer.sub_body,
            editor_by: this.editNewsAndUpdateDataFromServer.editor_by,
            place: this.editNewsAndUpdateDataFromServer.place,
            notes: this.editNewsAndUpdateDataFromServer.notes
          });

          //this.editNewsAndUpdateForm.controls['category_id'].setValue(1);
        }
        else {
          console.log(res);
        }
      }), (err) => {

      }
  }



  ngOnInit() {
  
  };

  getExamCategory(){
    this.examsService.getUserExamCategoryForMapping(1).
    subscribe(res => {
      this.categoryList = res;
    },
      err => {
        console.log(err);
        this.isLoadingResults = false;
      }
    )
  }
  onFormSubmit(form) {

    if (form.category == "-1") {
      alert("please select category");
    }
    else {
      this.isLoadingResults = true;

      form.user_id = localStorage.getItem('userID');
      form.news_id = this.newsID;
      console.log(form);

    
      this.examsService.editNewsAndUpdate(form)
        .subscribe(res => {
         if(res == 1){
          this.isLoadingResults = false;
          alert("update suessfully done !");
          this.router.navigate(['/daily-news']);
         }
         else{
          this.isLoadingResults = false;
          alert("server error");
          this.router.navigate(['/daily-news']);
         }
          //let id = res['id'];
         
        }, (err) => {
          console.log(err);
          this.isLoadingResults = false;
        });
    }
  }
}

