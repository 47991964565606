import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewEncapsulation} from '@angular/core';
import { AppUserService } from '../services/appUser/app-user.service';
import { AppUser } from '../models/app-user.model';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ExamsService } from '../services/exams/exams.service';

import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  /*
  displayedColumns: string[] = ['id', 'user_id', 'first_name', 'last_name', 'username',
    'email_id', 'mobile_no', 'address', 'qualification', 'is_active', 'is_locked', 'created_by',
    'created_date', 'updated_by', 'updated_date', 'user_type_id', 'last_used_date'];
  */

  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  displayedColumns: string[] = ['srno',
    'question',
    'question_options',
    'correct_answer',
    /* 'created_by',*/
    'created_date_time',
    'question_type_id',
    'is_active',
    'map_menu',
    'remove'

  ];
  //data: any[] = [];
  data: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoadingResults = true;

  public array: any;
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;

  //protected router: Router;


  categoryList: string[];
  courseSectionList: string[];
  courseList: string[];

  examMappingForm: FormGroup;

  examMappingFormForFilter: FormGroup;

  userID: any;

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private router: Router, private appUserService: AppUserService, public route: ActivatedRoute, private cdr: ChangeDetectorRef) {
    //console.log("constructor");

    this.examMappingForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category': [null, [Validators.required]],
      'courseSection': [null, [Validators.required]],
      'course': [null, [Validators.required]]
      //},{ updateOn: 'blur' });
    });
  }


  ngOnInit() {

    this.userID = localStorage.getItem('userID');
    if (this.userID == 0 || this.userID == null || this.userID == undefined) {
      this.userID = 0;
    }
    console.log(this.userID);

    //this.getQuestionList();

    this.examsService.getExamCategoryForUserIDWise(this.userID).
      subscribe(res => {
        console.log(res);
        this.categoryList = res;

        console.log(this.categoryList);
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
      this.isLoadingResults = false;
  }

  getQuestionList() {
    this.examsService.getQuestionList()
      .subscribe(res => {
        this.data = new MatTableDataSource<Element>(res);
        this.data.paginator = this.paginator;
        this.array = res;
        this.totalSize = this.array.length;
        this.iterator();
        //this.data = res;
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(start, end);
    this.data = part;
  }

  isActive(flag) {
    alert(flag);
  }

  isLocked(flag) {
    alert(flag);
  }

  disableQuestion(id, flag) {
    let txt = "";
    if (flag == "Y") {
      txt = "Are you sure to disabled the question ? "
    }
    else {
      txt = "Are you sure to enable the question ?"
    }
    let result = confirm(txt);
    flag = flag == "Y" ? "N" : "Y";

    var action = {
      "action":flag
    } 
    
    if (result == true) {
      this.examsService.questionAction(id, action).
        subscribe(res => {
          console.log(res);
          if(res > 0){
            this.getQuestionListWithFilters(this.examMappingFormForFilter);
            //this.getQuestionList();
          }
        },
          err => {
            console.log(err);
            this.isLoadingResults = false;
          }
        )
    } else {

    }
  }

  removeQuestion(userID) {
    alert("disableQuestion");
    //this.router.navigate(['users/userExamMapping', userID]);
    //this.router.navigate(['/users']);
  }

  onCategoryChange(e) {
    this.courseSectionList = [];
    this.courseList = [];

    //this.examMappingForm.get('courseSection').setValue(0);
    //this.examMappingForm.get('course').setValue(0);

    this.examMappingForm.get('courseSection').reset();
    this.examMappingForm.get('course').reset();

    this.examsService.getExamCourseSectionForUserIDWise(this.userID, e.value).
      subscribe(res => {
        console.log(res);
        this.courseSectionList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }

  onCourseSectionChange(e) {
    console.log(this.examMappingForm.get('category').value);
    console.log(this.examMappingForm.get('courseSection').value);
    //alert(this.examMappingForm.get('courseSection').value);
    if (this.examMappingForm.get('category').value != -1 && this.examMappingForm.get('courseSection').value != -1) {

      this.courseList = [];
      this.examMappingForm.get('course').reset();
      //this.examMappingForm.get('course').setValue(0);

      this.examsService.getExamCourseForUserIDWise(this.userID, this.examMappingForm.get('category').value, this.examMappingForm.get('courseSection').value).
        subscribe(res => {
          //console.log(res);
          this.courseList = res;
          console.log(res);
          this.isLoadingResults = false;
        },
          err => {
            console.log(err);
            this.isLoadingResults = false;
          }
        )
    }
  }

  onFormSubmit(form) {
    console.log(form);
    this.examMappingFormForFilter = form;
    //this.isLoadingResults = true;
    /*
    if(form.course != null && form.course != undefined && form.course != 0){
      form.course = form.course.join();
    }
    */
    this.getQuestionListWithFilters(form);
  }

  getQuestionListWithFilters(form) {
    this.examsService.getQuestionListWithFilters(form)
      .subscribe(res => {
        this.data = new MatTableDataSource<Element>(res);
        this.data.paginator = this.paginator;
        this.array = res;
        this.totalSize = this.array.length;
        this.iterator();
        //this.data = res;
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

  clearSearch() {

    //this.categoryList = [];
    this.courseSectionList = [];
    this.courseList = [];

    //this.examMappingForm.get('courseSection').setValue(0);
    //this.examMappingForm.get('course').setValue(0);

    this.examMappingForm.get('category').setValue('');
    this.examMappingForm.get('courseSection').setValue('');
    this.examMappingForm.get('course').setValue('');

    this.getQuestionList();
  }

}

