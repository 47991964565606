import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ExamsService } from '../../../services/exams/exams.service';

@Component({
  selector: 'app-exams-course-section-list',
  templateUrl: './exams-course-section-list.component.html',
  styleUrls: ['./exams-course-section-list.component.css']
})
export class ExamsCourseSectionListComponent implements OnInit {

  
  displayedColumns: string[] = ['srno',
  'exam_course_section_name',
  'exam_course_section_short_name',
  'exam_course_section_description',
  'is_active',
  /*'created_by',*/
  'created_date_time',
  //'map_menu',
  //'map_exam'
];
//data: any[] = [];
data: any;

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;
isLoadingResults = true;

public array: any;
public pageSize = 5;
public currentPage = 0;
public totalSize = 0;

protected router: Router;

constructor(public route: ActivatedRoute, private cdr: ChangeDetectorRef, private examsService : ExamsService) { }

ngOnInit() {
  this.getExamCourseSectionList();
}

getExamCourseSectionList() {
  this.examsService.getExamCourseSectionList()
    .subscribe(res => {
      this.data = new MatTableDataSource<Element>(res);
      this.data.paginator = this.paginator;
      this.array = res;
      this.totalSize = this.array.length;
      this.iterator();
      //this.data = res;
      this.isLoadingResults = false;
    }, err => {
      console.log(err);
      this.isLoadingResults = false;
    });
}

isActive(){

}

public handlePage(e: any) {
  this.currentPage = e.pageIndex;
  this.pageSize = e.pageSize;
  this.iterator();
}
private iterator() {
  const end = (this.currentPage + 1) * this.pageSize;
  const start = this.currentPage * this.pageSize;
  const part = this.array.slice(start, end);
  this.data = part;
}

}
