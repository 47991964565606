import { Component, OnInit,  ViewChild, ChangeDetectorRef } from '@angular/core';
import { AppUserService } from '../../services/appUser/app-user.service';
import { AppUser } from '../../models/app-user.model';
import { MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'

@Component({
  selector: 'app-app-user',
  templateUrl: './app-user.component.html',
  styleUrls: ['./app-user.component.css']
})

export class AppUserComponent implements OnInit {
  
  /*
  displayedColumns: string[] = ['id', 'user_id', 'first_name', 'last_name', 'username',
    'email_id', 'mobile_no', 'address', 'qualification', 'is_active', 'is_locked', 'created_by',
    'created_date', 'updated_by', 'updated_date', 'user_type_id', 'last_used_date'];
  */

  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  displayedColumns: string[] = ['srno', 
                                'first_name', 
                                'last_name',
                                'username', 
                                'email_id', 
                                'mobile_no', 
                                'last_used_date',
                                'user_type_id',
                                'is_active',
                                'is_locked',
                                //'map_menu',
                                'map_exam'
                                ];
  //data: any[] = [];
  data: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoadingResults = true;

  public array: any;
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;

  //protected router: Router;


  constructor(private router: Router, private appUserService: AppUserService, public route: ActivatedRoute, private cdr:ChangeDetectorRef) { 
    //console.log("constructor");
  }

  ngOnInit() {
    this.getUsersList();
    //console.log("ngOnInit");
  }

  getUsersList(){
    this.appUserService.getUserList()
      .subscribe(res => {
        this.data = new MatTableDataSource<Element>(res);
        this.data.paginator = this.paginator;
        this.array = res;
        this.totalSize = this.array.length;
        this.iterator();
        //this.data = res;
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(start, end);
    this.data = part;
  }

  isActive(flag){
    alert(flag);
  }

  isLocked(flag){
    alert(flag);
  }

  mapMenu(){
    console.log("map Menu");
  }

  mapExam(userID){
     this.router.navigate(['users/userExamMapping',userID]);
     //this.router.navigate(['/users']);
  }
}
