import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'


@Component({
  selector: 'app-exam-mapping',
  templateUrl: './exam-mapping.component.html',
  styleUrls: ['./exam-mapping.component.css']
})
export class ExamMappingComponent implements OnInit {

  constructor(public route: ActivatedRoute) { }

  ngOnInit() {
  }

}
