import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ExamsService } from 'src/app/services/exams/exams.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppUserService } from 'src/app/services/appUser/app-user.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { from } from 'rxjs';

@Component({
  selector: 'app-add-new-pre-exam',
  templateUrl: './add-new-pre-exam.component.html',
  styleUrls: ['./add-new-pre-exam.component.css']
})
export class AddNewPreExamComponent implements OnInit {

  addNewPreExamDateBtn = false;

  examName_: any;

  displayedColumns: string[] = ['srno',
    'name',
    //'sub_item_name_short',
    //'sub_name',
    'exam_date',
    'start_date',
    'end_date',
    'is_active',
    //'category_id',
    'question_ids',
    'map_menu',
    'remove'

  ];


  userID: any;
  alertMessage: any = "";

  noDataMessage = false;

  examMappingForm: FormGroup;
  categoryID: any;

  categoryList: any[];
  isLoadingResults = true;
  data = new MatTableDataSource();
  public array: any;
  public pageSize = 20;
  public currentPage = 0;
  public totalSize = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private router: Router,
    private appUserService: AppUserService, public route: ActivatedRoute, private changeDetectorRefs: ChangeDetectorRef,
    private cdr: ChangeDetectorRef) {
    this.examMappingForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category': [null, [Validators.required]]
      //},{ updateOn: 'blur' });
    });
  }
  ngOnInit() {
    this.userID = localStorage.getItem('userID');
    if (this.userID == 0 || this.userID == null || this.userID == undefined) {
      this.userID = 0;
    }
    console.log(this.userID);

    this.examsService.getExamCategoryForUserIDWise(this.userID).
      subscribe(res => {
        console.log(res);
        this.categoryList = res;
        this.isLoadingResults = false;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }

  onFormSubmit(form) {
    this.categoryID = form.category;
   
    this.data = new MatTableDataSource<Element>();
    this.getPreExamDateListWithDetails(form);
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/preExam/addPreExam']);
  }

  addNewPreExamDate() {
    //this.router.navigate(['/preExam/addPreExamDate', form.category]);
    if(this.categoryID){
      this.router.navigate(['/preExam/addPreExamDate', this.categoryID, this.examName_]);
    }
    else{
      alert("please first select exam category!");
    }
    
  }

  addNewPreExamTable(form) {
  
    if (form.category == null) {
      alert('please select');
    }
    else {
      // first check table exits or not
      // if exits return -2
      // else create table and return 1
      // else if any server issue return -1
      // else if no master table for pre exam -10
      console.log(form);
      this.categoryID = form.category;
      console.log(this.categoryID);
      this.examsService.addNewPreExamTable(this.categoryID)
        .subscribe(res => {
          console.log(res);

          if (res != null && res == -10) {
            alert("no master table available");
          }

          if (res != null && res == 2) {
            this.addNewPreExamDateBtn = true;
            alert("exam created suessfully!");
          }

          if (res != null && res.length > 0) {
            this.addNewPreExamDateBtn = true;
            alert("pre exam already created!");
          }

          this.isLoadingResults = false;
        }, err => {
          console.log(err);
          this.isLoadingResults = false;
        });
    }
  }

  // first check table exits or not
  // if exits return -2
  // else create table and return 1
  // else if any server issue return -1
  // else if no master table for pre exam -10
  getPreExamDateListWithDetails(form) {
    this.categoryID = form.category;
    console.log(this.categoryID);
    //this.userID = localStorage.getItem('userID');
    this.examsService.getPreExamDateListWithDetails(this.categoryID)
      .subscribe(res => {
        //console.log(res[0]);
        //console.log(res);
        if (res == null) {
          alert("server null issue");
        }
        else
        if (res.length == 0) {
          this.addNewPreExamDateBtn = true;
          this.addNewPreExamDateBtn = true;
          alert("No Pre Exam dates available");
        }
        else
        if (res != null && res == -10) {
          alert("no master table available");
          
        }
        else
        if (res != null && res == "0") {
          this.addNewPreExamDateBtn = true;
          this.noDataMessage = true;
        }
        else {
          this.noDataMessage = false;
          this.addNewPreExamDateBtn = true;
          this.data = new MatTableDataSource<Element>(res);

          this.changeDetectorRefs.detectChanges();

          this.data.paginator = this.paginator;
          this.array = res;
          this.totalSize = this.array.length;
          this.iterator();
        }
        //this.data = res;
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }


  deletePreExamDate(id) {

    if (confirm("Are you sure to delete ?")) {
      this.examsService.deletePreExamDate(id, this.categoryID)
        .subscribe(res => {
          if (res && res == true) {

            alert("delete successfully done !");
            this.reloadComponent();
            /*this.alertMessage = res['message'];
           
            setTimeout(() => {
              this.alertMessage = "";
              this.reloadComponent();
            }, 2000);
            */
          }

          console.log(res);
        }, (err) => {
          console.log(err);
        })
    }

  }

  editNewPreExam(id){
    if(this.categoryID){
      this.router.navigate(['/preExam/editPreExamDate', this.categoryID, this.examName_, id]);
    }
    else{
      alert("please first select exam category!");
    }
  }
  setCategoryName(e){
    console.log(e);

    this.examName_ = this.categoryList.find(x => x.id === e.value).exam_category_name;
    console.log(this.examName_);
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(start, end);
    this.data = part;
  }
}
