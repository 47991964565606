import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ExamsService } from '../services/exams/exams.service';
import { AppUserService } from '../services/appUser/app-user.service';

@Component({
  selector: 'app-daily-news',
  templateUrl: './daily-news.component.html',
  styleUrls: ['./daily-news.component.css']
})


export class DailyNewsComponent implements OnInit {

  displayedColumns: string[] = ['srno',
    'title',
    //'sub_item_name_short',
    'body',
    'sub_body',
    'editor_by',
    'place',
    'notes',
    'is_active',
    'edit',
    'remove'
];

  alertMessage: any = "";

  noDataMessage = false;

  examMappingForm: FormGroup;
  userID: any;

  isLoadingResults = true;

  categoryList: string[];
 
  categoryID: any;

  data=  new MatTableDataSource();
  public array: any;
  public pageSize = 20;
  public currentPage = 0;
  public totalSize = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private router: Router,
    private appUserService: AppUserService, public route: ActivatedRoute, private changeDetectorRefs: ChangeDetectorRef,
    private cdr: ChangeDetectorRef) {
    this.examMappingForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category': [null, [Validators.required]],
      'courseSection': [null],
      'course': [null]
      //},{ updateOn: 'blur' });
    });
  }

  ngOnInit() {

    this.userID = localStorage.getItem('userID');
    if (this.userID == 0 || this.userID == null || this.userID == undefined) {
      this.userID = 0;
    }
    console.log(this.userID);

    this.examsService.getExamCategoryForUserIDWise(this.userID).
      subscribe(res => {
        console.log(res);
        this.categoryList = res;
        this.isLoadingResults = false;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }



  onFormSubmit(form) {
    this.data =  new MatTableDataSource<Element>();
    this.getDailyNews(form);
  }


  getDailyNews(form) {
    this.categoryID = form.category;
    console.log(this.categoryID);
    this.examsService.getDailyNews(form)
      .subscribe(res => {

        if (res.length > 0 && res[0] == "0") {
          this.noDataMessage = true;
        }
        else {
          this.noDataMessage = false;
          this.data = new MatTableDataSource<Element>(res);

          this.changeDetectorRefs.detectChanges();

          this.data.paginator = this.paginator;
          this.array = res;
          this.totalSize = this.array.length;
          this.iterator();
        }
        //this.data = res;
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }


  editNewsAndUpdate(id) {
    this.router.navigate(['/daily-news/daily-news-edit', id, this.categoryID]);
    //this.router.navigate(['/daily-news/daily-news-edit',  {"id" : id, "categoryID": this.categoryID}]);
  }

  deleteNewsAndUpdate(id) {

    if (confirm("Are you sure to delete ?")) {
      this.examsService.deleteNewsAndUpdate(id, this.categoryID)
        .subscribe(res => {
          if (res && res == true) {
            
            alert("delete successfully done !");
            this.reloadComponent();
            /*this.alertMessage = res['message'];
           
            setTimeout(() => {
              this.alertMessage = "";
              this.reloadComponent();
            }, 2000);
            */
          }

          console.log(res);
        }, (err) => {
          console.log(err);
          /*
          this.alertMessage = err;
          setTimeout(() => {
            this.alertMessage = "";
          }, 2000);
          */
        })
    }

  }


  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/daily-news']);
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(start, end);
    this.data = part;
  }

}