import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users/users.service';
import { UserModel } from '../models/user.model';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  displayedColumns: string[] = ['id','first_name', 'last_name', 'mobile_no','email_id', 'address', 'qualification','date_time'];
  data: UserModel[] = [];
  isLoadingResults = true;
  
  constructor(private usersService: UsersService) { 
    
  }

  ngOnInit() {
    this.usersService.getUserList()
      .subscribe(res => {
        this.data = res;
        console.log(this.data);
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

}
