import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExamsService } from 'src/app/services/exams/exams.service';
import { AppUserService } from 'src/app/services/appUser/app-user.service';

@Component({
  selector: 'app-edit-exam-sub-item',
  templateUrl: './edit-exam-sub-item.component.html',
  styleUrls: ['./edit-exam-sub-item.component.css']
})
export class EditExamSubItemComponent implements OnInit {

  addExamSubItem: FormGroup;

  isLoadingResults = false;

  categoryList: string[];
  courseSectionList: string[];
  courseList: string[];

  testNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  editDataFromServer: any;

  examSubItemId: any;

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private router: Router,
    private appUserService: AppUserService, public route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {

    this.examSubItemId = this.route.snapshot.params['id'];

    this.getSubExamDetails(this.route.snapshot.params['id']);

    this.addExamSubItem = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category': [null],
      'courseSection': [null],
      'course': [null, [Validators.required]],
      'subExamName': [null, [Validators.required]],
      'subExamShortName': [null, [Validators.required]],
      'totalTime': [null, [Validators.required]],
      'noOfQuestion': [null, [Validators.required]],
      'fileName': [null, [Validators.required]],
      'markForCorrect': [null, [Validators.required]],
      'markForWrong': [null, [Validators.required]],
      'testNumber': [null, [Validators.required]]
      //},{ updateOn: 'blur' });
    });

  }

  ngOnInit() {

    this.examsService.getUserExamCategoryForMapping(1).
      subscribe(res => {
        //console.log(res);
        this.categoryList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }

  getSubExamDetails(id) {
    this.examsService.getExamSubItemForEdit(id)
      .subscribe(res => {
        if (res) {
          this.editDataFromServer = res[0];
          console.log(res);
          this.addExamSubItem.setValue({
            category: 0,
            courseSection: 0,
            course: this.editDataFromServer.course_id,
            subExamName: this.editDataFromServer.sub_item_name,
            subExamShortName: this.editDataFromServer.sub_item_name_short,
            totalTime: this.editDataFromServer.exam_time,
            noOfQuestion: this.editDataFromServer.no_of_question,
            fileName: this.editDataFromServer.file_name,
            markForCorrect: this.editDataFromServer.mark_for_correct,
            markForWrong: this.editDataFromServer.mark_for_wrong,
            testNumber: this.editDataFromServer.test_number
          });
        }
        else {
          console.log(res);
        }
      }), (err) => {

      }
  }


  onCourseSectionChange(e) {
    if (this.addExamSubItem.get('category').value != -1 && this.addExamSubItem.get('courseSection').value != -1) {
      this.courseList = [];
      this.addExamSubItem.get('course').setValue(0);
      let ids = this.addExamSubItem.get('category').value + "##" + this.addExamSubItem.get('courseSection').value;
      this.examsService.getUserExamCourseForMapping(1, this.addExamSubItem.get('category').value, this.addExamSubItem.get('courseSection').value).
        subscribe(res => {
          //console.log(res);
          if (res.toString() == "1") {

          } else {
            this.courseList = res;
          }
        },
          err => {
            console.log(err);
            this.isLoadingResults = false;
          }
        )
    }
  }

  onChange(e) {
    //console.dir(e.value);
    //console.log(e.value);
    this.courseSectionList = [];
    this.courseList = [];
    this.addExamSubItem.get('courseSection').setValue(0);
    this.addExamSubItem.get('course').setValue(0);
    this.examsService.getUserExamCourseSectionForMapping(1, e.value).
      subscribe(res => {
        if (res.toString() == "1") {

        }
        else {

          this.courseSectionList = res;
        }
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }

  onFormSubmit(form) {
    if (form.category == "-1") {
      alert("please select category");
    }
    else {
      this.isLoadingResults = true;

      console.log(form);


      this.examsService.updateExamSubItem(form, this.examSubItemId)
        .subscribe(res => {
          //let id = res['id'];
          if(res > 0){
            alert('Exam sub item successfully updated.');
          }
          this.isLoadingResults = false;
          this.router.navigate(['exam-sub-list']);

        }, (err) => {
          console.log(err);
          this.isLoadingResults = false;
        });

    }
  }

}
