import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exams-category-edit',
  templateUrl: './exams-category-edit.component.html',
  styleUrls: ['./exams-category-edit.component.css']
})
export class ExamsCategoryEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
