import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel } from '../../../models/user.model';
import { UsersService } from '../../../services/users/users.service';

@Component({
  selector: 'app-detail-user',
  templateUrl: './detail-user.component.html',
  styleUrls: ['./detail-user.component.css']
})
export class DetailUserComponent implements OnInit {

  user: UserModel = {
    id: null,
    student_id : '',
    first_name: '',
    last_name: '',
    mobile_no: '',
    email_id: '',
    password: '',
    address: '',
    qualification: '',
    date_time: '' 
  }

  userID:Number;

  isLoadingResults = true;

  constructor(private route: ActivatedRoute, private userService: UsersService, private router: Router) { }

  ngOnInit() {
    this.getUserDetails(this.route.snapshot.params['id']);
  }

  getUserDetails(id) {
    
    this.userService.getUserById(id)
      .subscribe(data => {
        this.user = data;
        console.log(this.user);
        this.userID = this.user[0].id;
        this.isLoadingResults = false;
      });
  }

  deleteUser(id) {
    this.isLoadingResults = true;
    this.userService.deleteUser(id)
      .subscribe(res => {
          this.isLoadingResults = false;
          this.router.navigate(['/users']);
        }, (err) => {
          console.log(err);
          this.isLoadingResults = false;
        }
      );
  }
  
}

