import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ExamsService } from '../../services/exams/exams.service';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-exam-mapping-add',
  templateUrl: './exam-mapping-add.component.html',
  styleUrls: ['./exam-mapping-add.component.css']
})
export class ExamMappingAddComponent implements OnInit {

  examMappingForm: FormGroup;

  isLoadingResults = false;

  //categoryList: string[] = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6'];
  //courseSectionList: string[] = ['Section 1', 'Section 2', 'Section 3', 'Section 4', 'Section 5', 'Section 6'];
  //courseList: string[] = ['Course 1', 'Course 2', 'Course 3', 'Course 4', 'Course 5', 'Course 6'];

  categoryList: string[];
  courseSectionList: string[];
  courseList: string[];


  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  categoryListArr: any[];
  courseSectionArr: any[];


  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private router: Router) {

    this.examMappingForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category': [null, [Validators.required]],
      'courseSection': [null, [Validators.required]],
      'course': [null, [Validators.required]]
      //},{ updateOn: 'blur' });
    });
  }

  ngOnInit() {
    this.examsService.getExamCategoryForMapping(1).
      subscribe(res => {
        console.log(res);
        this.categoryList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  };

  onChange(e) {
    //console.dir(e.value);
    this.courseSectionList = [];
    this.courseList = [];

    this.examMappingForm.get('courseSection').setValue(0);
    this.examMappingForm.get('course').setValue(0);


    this.examsService.getExamCourseSectionForMapping(1).
      subscribe(res => {
        console.log(res);
        this.courseSectionList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )

    this.examsService.getExamCourseForMapping(1).
      subscribe(res => {
        console.log(res);
        this.courseList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }

  onFormSubmit(form) {
   
    this.isLoadingResults = true;
    form.course = form.course.join();
    console.log(form);
    this.examsService.addExamMapping(form)
      .subscribe(res => {
        //let id = res['id'];
        this.isLoadingResults = false;
        //this.router.navigate(['/product-details', id]);
        //this.router.navigate(['/exams/examSectionList']);
        //location.href = "/users";
        //this.router.navigateByUrl('users');
        //this.router.navigate([ 'users' ], { relativeTo: this.route })
      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });
      
  }
}
