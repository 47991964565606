import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { MaterialModule } from './material.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';

import { HttpClientModule } from '@angular/common/http';
import { UsersService } from './services/users/users.service'

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { UsersComponent } from './users/users.component';
import { ExamslistComponent } from './examslist/examslist.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { EditUserComponent } from './users/edit-user/edit-user/edit-user.component';
import { DetailUserComponent } from './users/detail-user/detail-user/detail-user.component';
import { AppUserComponent } from './appUser/app-user/app-user.component';
import { AppUserAddComponent } from './appUser/app-user-add/app-user-add.component';
import { AppUserEditComponent } from './appUser/app-user-edit/app-user-edit.component';
import { AppUserDetailComponent } from './appUser/app-user-detail/app-user-detail.component';
import { AppUserService } from './services/appUser/app-user.service';
import { ExamsService } from './services/exams/exams.service';
import { UniqueAppUserValidationDirective } from './directives/unique-app-user-validation.directive';
import { ExamsCategoryListComponent } from './examslist/exams-category/exams-category-list/exams-category-list.component';
import { ExamsCategoryAddComponent } from './examslist/exams-category/exams-category-add/exams-category-add.component';
import { ExamsCategoryEditComponent } from './examslist/exams-category/exams-category-edit/exams-category-edit.component';
import { ExamsCourseEditComponent } from './examslist/exams-course/exams-course-edit/exams-course-edit.component';
import { ExamsCourseAddComponent } from './examslist/exams-course/exams-course-add/exams-course-add.component';
import { ExamsCourseListComponent } from './examslist/exams-course/exams-course-list/exams-course-list.component';
import { ExamsCourseSectionListComponent } from './examslist/exams-course-section/exams-course-section-list/exams-course-section-list.component';
import { ExamsCourseSectionAddComponent } from './examslist/exams-course-section/exams-course-section-add/exams-course-section-add.component';
import { ExamsCourseSectionEditComponent } from './examslist/exams-course-section/exams-course-section-edit/exams-course-section-edit.component';
import { ExamMappingComponent } from './exam-mapping/exam-mapping.component';
import { ExamMappingAddComponent } from './exam-mapping/exam-mapping-add/exam-mapping-add.component';
import { AppUserExamMappingComponent } from './appUser/app-user-exam-mapping/app-user-exam-mapping.component';
import { QuestionComponent } from './question/question.component';
import { AddQuestionComponent } from './question/add-question/add-question.component';
import { QuestionOptionsPipePipe } from './pipe/question-options-pipe.pipe';
import { ExamSubItemListComponent } from './exam-sub-item-list/exam-sub-item-list.component';
import { AddExamSubItemComponent } from './exam-sub-item-list/add-exam-sub-item/add-exam-sub-item.component';
import { PreExamHomeComponent } from './pre-exam/pre-exam-home/pre-exam-home.component';
import { EditExamSubItemComponent } from './exam-sub-item-list/edit-exam-sub-item/edit-exam-sub-item.component';

import { DailyNewsComponent } from './daily-news/daily-news.component';
import { AddDailyNewsComponent } from './daily-news/add-daily-news/add-daily-news.component';
import { EditDailyNewsComponent } from './daily-news/edit-daily-news/edit-daily-news.component';
import { AddNewPreExamComponent } from './pre-exam/add-new-pre-exam/add-new-pre-exam.component';
import { AddNewPreExamDateComponent } from './pre-exam/add-new-pre-exam-date/add-new-pre-exam-date.component';
import { EditNewPreExamDateComponent } from './pre-exam/edit-new-pre-exam-date/edit-new-pre-exam-date.component';


export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    AboutUsComponent,
    UsersComponent,
    ExamslistComponent,
    AddUserComponent,
    EditUserComponent,
    DetailUserComponent,
    AppUserComponent,
    AppUserAddComponent,
    AppUserEditComponent,
    AppUserDetailComponent,
    UniqueAppUserValidationDirective,
    ExamsCategoryListComponent,
    ExamsCategoryAddComponent,
    ExamsCategoryEditComponent,
    ExamsCourseEditComponent,
    ExamsCourseAddComponent,
    ExamsCourseListComponent,
    ExamsCourseSectionListComponent,
    ExamsCourseSectionAddComponent,
    ExamsCourseSectionEditComponent,
    ExamMappingComponent,
    ExamMappingAddComponent,
    AppUserExamMappingComponent,
    QuestionComponent,
    AddQuestionComponent,
    QuestionOptionsPipePipe,
    ExamSubItemListComponent,
    AddExamSubItemComponent,
    PreExamHomeComponent,
    EditExamSubItemComponent,
    DailyNewsComponent,
    AddDailyNewsComponent,
    EditDailyNewsComponent,
    AddNewPreExamComponent,
    AddNewPreExamDateComponent,
    EditNewPreExamDateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:3000'],
        //blacklistedRoutes: ['localhost:4000/api/auth']
      }
    })
  ],
  providers: [
    HttpClientModule,
    UsersService,
    AppUserService,
    ExamsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
