import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { UsersService } from '../../../services/users/users.service'
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  userForm: FormGroup;
  _id: number = null;
  first_name: string = '';
  last_name: string = '';
  mobile_no: number = null;
  email_id: string = '';
  password: string = '';
  address: string = '';
  qualification: string = '';
  isLoadingResults = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UsersService) { }

  ngOnInit() {
    this.getUser(this.route.snapshot.params['id']);
    this.userForm = this.formBuilder.group({
      'first_name' : [null, Validators.required],
      'last_name' : [null, Validators.required],
      'mobile_no' : [null, Validators.required],
      'email_id' : [null, Validators.required],
      'address' : [null, Validators.required],
      'qualification' : [null, Validators.required]
    });
  }

  getUser(id) {
    this.userService.getUserById(id).subscribe(data => {
      data = data[0];
      this._id = data.id;
      this.userForm.setValue({
        'first_name': data.first_name,
        'last_name': data.last_name,
        'mobile_no': data.mobile_no,
        'email_id': data.email_id,
        'address': data.address,
        'qualification': data.qualification
      });
    });
  }

  onFormSubmit(form:NgForm) {
    this.isLoadingResults = true;
    this.userService.updateUser(this._id, form)
      .subscribe(res => {
          //let id = res['_id'];
          let id = this._id;
          this.isLoadingResults = false;
          this.router.navigate(['/detailuser', id]);
        }, (err) => {
          console.log(err);
          this.isLoadingResults = false;
        }
      );
  }

  userDetails() {
    this.router.navigate(['/detailuser', this._id]);
  }
}
