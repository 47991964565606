import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatToolbarModule,
    MatTreeModule,
    MatDialogModule,
    MatTableModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule
    
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatToolbarModule,
        MatDialogModule,
        MatTreeModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatSortModule,
        MatGridListModule,
        MatSelectModule,
        MatListModule,
        MatChipsModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatExpansionModule,
        DragDropModule,
        MatDatepickerModule,
        MatNativeDateModule
    ],
    exports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatToolbarModule,
        MatDialogModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatSortModule,
        MatGridListModule,
        MatSelectModule,
        MatChipsModule,
        MatListModule,
        MatTreeModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatExpansionModule,
        DragDropModule,
        MatDatepickerModule,
        MatNativeDateModule
    ]
})
export class MaterialModule { }