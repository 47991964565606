import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ExamsService } from '../../services/exams/exams.service';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-app-user-exam-mapping',
  templateUrl: './app-user-exam-mapping.component.html',
  styleUrls: ['./app-user-exam-mapping.component.css']
})
export class AppUserExamMappingComponent implements OnInit {
  examMappingForm: FormGroup;

  isLoadingResults = false;

  //categoryList: string[] = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6'];
  //courseSectionList: string[] = ['Section 1', 'Section 2', 'Section 3', 'Section 4', 'Section 5', 'Section 6'];
  //courseList: string[] = ['Course 1', 'Course 2', 'Course 3', 'Course 4', 'Course 5', 'Course 6'];

  categoryList: string[];
  courseSectionList: string[];
  courseList: string[];


  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  categoryListArr: any[];
  courseSectionArr: any[];

  _userID: any;

  _categoryMapped: boolean = false;
  _courseSectionMapped: boolean = false;
  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private route: ActivatedRoute, private router: Router) {

    this.examMappingForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category': [null, [Validators.required, Validators.min(1)]],
      'courseSection': [null, [Validators.required]],
      'course': [null, [Validators.required]]
      //},{ updateOn: 'blur' });
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this._userID = params.id;
    });

    this.examsService.getUserExamCategoryForMapping(1).
      subscribe(res => {
        //console.log(res);
        this.categoryList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  };

  onCourseSectionChange(e) {

    //alert(this.examMappingForm.get('courseSection').value);

    if (this.examMappingForm.get('category').value != -1 && this.examMappingForm.get('courseSection').value != -1) {

      this.courseList = [];
      this.examMappingForm.get('course').setValue(0);

      let ids = this.examMappingForm.get('category').value + "##" + this.examMappingForm.get('courseSection').value;

      this.examsService.getUserExamCourseForMapping(this._userID, this.examMappingForm.get('category').value, this.examMappingForm.get('courseSection').value).
        subscribe(res => {
          //console.log(res);
          if (res.toString() == "1") {
            //alert("already mapped");
            this._courseSectionMapped = true;
          } else {
            this._courseSectionMapped = false;
            this.courseList = res;
          }

        },
          err => {
            console.log(err);
            this.isLoadingResults = false;
          }
        )
    }
  }

  onChange(e) {
    //console.dir(e.value);
    //console.log(e.value);

    this.courseSectionList = [];
    this.courseList = [];

    this.examMappingForm.get('courseSection').setValue(0);
    this.examMappingForm.get('course').setValue(0);


    this.examsService.getUserExamCourseSectionForMapping(this._userID, e.value).
      subscribe(res => {
        if (res.toString() == "1") {
          //alert("already mapped");
          this._categoryMapped = true;
        }
        else {
          this._categoryMapped = false;
          this.courseSectionList = res;
        }
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )

    /* 
    this.examsService.getExamCourseForMapping(1).
      subscribe(res => {
        console.log(res);
        this.courseList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
      */
  }

  onFormSubmit(form) {
    alert(form.course);
    if (form.category == "-1") {
      alert("please select category");
    }
    else {
      this.isLoadingResults = true;
      if (form.course != 0) {
        form.course = form.course.join();
      }
      if (form.courseSection == 0) {
        form.courseSection = 1;
      }
      if (form.course == 0) {
        form.course = 1;
      }
      console.log(form);
      form.userID = parseInt(this._userID);

      this.examsService.addUserExamMapping(form)
        .subscribe(res => {
          //let id = res['id'];
          this.isLoadingResults = false;
          //this.router.navigate(['/product-details', id]);
          //this.router.navigate(['/exams/examSectionList']);
          //location.href = "/users";
          //this.router.navigateByUrl('users');
          //this.router.navigate([ 'users' ], { relativeTo: this.route })
        }, (err) => {
          console.log(err);
          this.isLoadingResults = false;
        });


    }

    //this.isLoadingResults = true;
    //form.course = form.course.join();
    //console.log(form);
    /*
    this.examsService.addExamMapping(form)
      .subscribe(res => {
        //let id = res['id'];
        this.isLoadingResults = false;
        //this.router.navigate(['/product-details', id]);
        //this.router.navigate(['/exams/examSectionList']);
        //location.href = "/users";
        //this.router.navigateByUrl('users');
        //this.router.navigate([ 'users' ], { relativeTo: this.route })
      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });

      */
  }
}
