import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { UsersService } from '../../services/users/users.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  userForm: FormGroup;
  first_name: string = '';
  last_name: string = '';
  mobile_no: number = null;
  email_id: string = '';
  password: string = '';
  address: string = '';
  qualification: string = '';
  isLoadingResults = false;

  constructor(private router: Router,
    private userService: UsersService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      'student_id':['tmp101'],
      'first_name': [null, Validators.required],
      'last_name': [null, Validators.required],
      'mobile_no': [null, Validators.required],
      'email_id': [null, Validators.required],
      'password': [null, Validators.required],
      'address': [null, Validators.required],
      'qualification': [null, Validators.required]
    });
  }

  onFormSubmit(form: NgForm) {
    this.isLoadingResults = true;
    this.userService.addUser(form)
      .subscribe(res => {
        //let id = res['id'];
        this.isLoadingResults = false;
        //this.router.navigate(['/product-details', id]);
        this.router.navigate(['/users']);
      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

}
