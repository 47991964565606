import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login/login.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
    `.angular-logo {
        margin: 0 4px 3px 0;
        height: 35px;
        vertical-align: middle;
    }
    .fill-remaining-space {
      flex: 1 1 auto;
    }
    footer{
      position: fixed;
      bottom: 0px;
      background: #ccc;
      width: 100%;
      text-align: center;
      margin: auto;
      left:0px;
      z-index:1;
    }
    footer>.mat-toolbar{
      background: #3f51b5;
    color: rgb(193, 193, 193);
    }
    .center{
      text-align: center;
      width: 100%;
    }
    .active {
      color: #FFC107;
      
    `
  ]
})
export class HeaderComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;                  // {1}

  accessType$: Observable<boolean>;


  constructor(private loginService: LoginService) { }

  ngOnInit() {

    this.isLoggedIn$ = this.loginService.isLoggedIn; // {2}

    this.accessType$ = this.loginService.getAccessType_;

    console.log("prince paliwal");
    console.log(this.accessType$);

    //this.accessType = this.loginService.getAccessType();
    //alert(this.accessType);

  }

  onLogout() {
    this.loginService.logout();                      // {3}
  }
}