import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExamsService } from 'src/app/services/exams/exams.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-new-pre-exam-date',
  templateUrl: './add-new-pre-exam-date.component.html',
  styleUrls: ['./add-new-pre-exam-date.component.css']
})
export class AddNewPreExamDateComponent implements OnInit {

  categoryID: any;
  categoryName: any;
  addNewPreExamDateForm: FormGroup;

  isLoadingResults = false;

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private route: ActivatedRoute, private router: Router) {
    this.categoryID = this.route.snapshot.params['id'];
    this.categoryName = this.route.snapshot.params['categoryName'];


    this.addNewPreExamDateForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'name': [null, [Validators.required]],
      'sub_name': [null, [Validators.required]],
      'exam_date': [null, [Validators.required]],
      'start_date': [null, Validators.required],
      'end_date': [null, Validators.required],
      'is_active': [null, Validators.required]
    });

  }

  // first check table exits or not
  // if exits return -2 : no table found
  // else sucessfully added
  // else if any server issue return -1
  // else if no master table for pre exam -10
  onFormSubmit(form) {
    this.isLoadingResults = true;
    form.user_id = localStorage.getItem('userID');
    form.category_id = this.categoryID;
    form.category_name = this.categoryName;
    console.log(form);
    this.examsService.addNewPreExamDate(form)
      .subscribe(res => {
        //let id = res['id'];
        this.isLoadingResults = false;
        if(res != null && res == -2 ){
          alert('no table found');
        }
        if(res != null && res == -10 ){
          alert('no master table found');
        }
        if(res != null && res == 1 ){
          alert("suessfully done !");
          this.router.navigate(['/preExam/addPreExam']);
        }
        this.isLoadingResults = false;
      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

  ngOnInit() {
  }

}
