import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ExamsService } from '../../services/exams/exams.service';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-add-daily-news',
  templateUrl: './add-daily-news.component.html',
  styleUrls: ['./add-daily-news.component.css']
})
export class AddDailyNewsComponent implements OnInit {

  addNewsAndUpdateForm: FormGroup;

  isLoadingResults = false;

  categoryList: string[];

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private route: ActivatedRoute, private router: Router) {

    this.addNewsAndUpdateForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category_id': [null, [Validators.required, Validators.min(1)]],
      'title': [null, [Validators.required]],
      'body': [null, [Validators.required]],
      'sub_body': [null],
      'editor_by': [null],
      'place': [null],
      'notes': [null]
    });
  }

  ngOnInit() {
    this.examsService.getUserExamCategoryForMapping(1).
      subscribe(res => {
        this.categoryList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  };


  onFormSubmit(form) {

    if (form.category == "-1") {
      alert("please select category");
    }
    else {
      this.isLoadingResults = true;

      form.user_id = localStorage.getItem('userID');
      console.log(form);

    
      this.examsService.addNewsAndUpdate(form)
        .subscribe(res => {
          //let id = res['id'];
          this.isLoadingResults = false;
          alert("suessfully done !");
          this.router.navigate(['/daily-news']);
        }, (err) => {
          console.log(err);
          this.isLoadingResults = false;
        });

     

    }
  }
}

