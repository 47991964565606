import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ExamsService } from '../../services/exams/exams.service';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-exam-sub-item',
  templateUrl: './add-exam-sub-item.component.html',
  styleUrls: ['./add-exam-sub-item.component.css']
})
export class AddExamSubItemComponent implements OnInit {
  addExamSubItem: FormGroup;

  isLoadingResults = false;

  categoryList: string[];
  courseSectionList: string[];
  courseList: string[];

  testNumber = [1,2,3,4,5,6,7,8,9,10];

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private route: ActivatedRoute, private router: Router) {

    this.addExamSubItem = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category': [null, [Validators.required, Validators.min(1)]],
      'courseSection': [null, [Validators.required]],
      'course': [null, [Validators.required]],
      'subExamName': [null, [Validators.required]],
      'subExamShortName': [null, [Validators.required]],
      'totalTime': [null, [Validators.required]],
      'noOfQuestion': [null, [Validators.required]],
      'fileName' : [null, [Validators.required]],
      'markForCorrect': [null, [Validators.required]],
      'markForWrong': [null, [Validators.required]],
      'testNumber': [null, [Validators.required]]
      //},{ updateOn: 'blur' });
    });
  }

  ngOnInit() {


    this.examsService.getUserExamCategoryForMapping(1).
      subscribe(res => {
        //console.log(res);
        this.categoryList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  };

  onCourseSectionChange(e) {

    if (this.addExamSubItem.get('category').value != -1 && this.addExamSubItem.get('courseSection').value != -1) {

      this.courseList = [];
      this.addExamSubItem.get('course').setValue(0);

      let ids = this.addExamSubItem.get('category').value + "##" + this.addExamSubItem.get('courseSection').value;

      this.examsService.getUserExamCourseForMapping(1, this.addExamSubItem.get('category').value, this.addExamSubItem.get('courseSection').value).
        subscribe(res => {
          //console.log(res);
          if (res.toString() == "1") {

          } else {
            this.courseList = res;
          }

        },
          err => {
            console.log(err);
            this.isLoadingResults = false;
          }
        )
    }
  }

  onChange(e) {
    //console.dir(e.value);
    //console.log(e.value);

    this.courseSectionList = [];
    this.courseList = [];

    this.addExamSubItem.get('courseSection').setValue(0);
    this.addExamSubItem.get('course').setValue(0);


    this.examsService.getUserExamCourseSectionForMapping(1, e.value).
      subscribe(res => {
        if (res.toString() == "1") {

        }
        else {

          this.courseSectionList = res;
        }
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )

   
  }

  onFormSubmit(form) {
    alert(form.course);
    if (form.category == "-1") {
      alert("please select category");
    }
    else {
      this.isLoadingResults = true;
      
      console.log(form);
      
      this.examsService.addExamSubItem(form)
        .subscribe(res => {
          //let id = res['id'];
          this.isLoadingResults = false;
        }, (err) => {
          console.log(err);
          this.isLoadingResults = false;
        });


    }
 }
}
