import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AuthGuard } from './auth/auth.guard';
import { LoginGuard } from './services/login/login.guard';
import { UsersComponent } from './users/users.component';
import { ExamslistComponent } from './examslist/examslist.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { DetailUserComponent } from './users/detail-user/detail-user/detail-user.component';
import { EditUserComponent } from './users/edit-user/edit-user/edit-user.component';
import { AppUserComponent } from './appUser/app-user/app-user.component';
import { AppUserDetailComponent } from './appUser/app-user-detail/app-user-detail.component';
import { AppUserAddComponent } from './appUser/app-user-add/app-user-add.component';
import { ExamsCategoryListComponent } from './examslist/exams-category/exams-category-list/exams-category-list.component';
import { ExamsCourseSectionListComponent } from './examslist/exams-course-section/exams-course-section-list/exams-course-section-list.component';
import { ExamsCourseListComponent } from './examslist/exams-course/exams-course-list/exams-course-list.component';
import { ExamsCategoryAddComponent } from './examslist/exams-category/exams-category-add/exams-category-add.component';
import { ExamsCourseAddComponent } from './examslist/exams-course/exams-course-add/exams-course-add.component';
import { ExamsCourseSectionAddComponent } from './examslist/exams-course-section/exams-course-section-add/exams-course-section-add.component';
import { ExamMappingComponent } from './exam-mapping/exam-mapping.component';
import { ExamMappingAddComponent } from './exam-mapping/exam-mapping-add/exam-mapping-add.component';
import { AppUserExamMappingComponent } from './appUser/app-user-exam-mapping/app-user-exam-mapping.component';
import { QuestionComponent } from './question/question.component';
import { AddQuestionComponent } from './question/add-question/add-question.component';
import { ExamSubItemListComponent } from './exam-sub-item-list/exam-sub-item-list.component';
import { AddExamSubItemComponent } from './exam-sub-item-list/add-exam-sub-item/add-exam-sub-item.component';
import { PreExamHomeComponent } from './pre-exam/pre-exam-home/pre-exam-home.component';
import { EditExamSubItemComponent } from './exam-sub-item-list/edit-exam-sub-item/edit-exam-sub-item.component';
import { DailyNewsComponent } from './daily-news/daily-news.component';
import { AddDailyNewsComponent } from './daily-news/add-daily-news/add-daily-news.component';
import { EditDailyNewsComponent } from './daily-news/edit-daily-news/edit-daily-news.component';
import { AddNewPreExamComponent } from './pre-exam/add-new-pre-exam/add-new-pre-exam.component';
import { AddNewPreExamDateComponent } from './pre-exam/add-new-pre-exam-date/add-new-pre-exam-date.component';
import { EditNewPreExamDateComponent } from './pre-exam/edit-new-pre-exam-date/edit-new-pre-exam-date.component';

const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [LoginGuard] },
  {
    path: 'users', component: AppUserComponent, canActivate: [LoginGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: '', component: AppUserComponent, pathMatch: 'full' },
      { path: 'addAppUser', component: AppUserAddComponent },
      { path: 'detailsAppUser', component: AppUserDetailComponent },
      { path: 'userExamMapping/:id', component: AppUserExamMappingComponent }
    ]
  },
  { path: 'adduser', component: AddUserComponent, canActivate: [LoginGuard] },
  { path: 'edituser/:id', component: EditUserComponent, canActivate: [LoginGuard] },
  { path: 'detailuser/:id', component: DetailUserComponent, canActivate: [LoginGuard] },
  {
    path: 'exams', component: ExamslistComponent, canActivate: [LoginGuard],
    children: [
      { path: '', component: ExamslistComponent },
      {
        path: 'examCategoryList', component: ExamsCategoryListComponent, children: [
          { path: '', component: ExamsCategoryListComponent },
          { path: 'addExamCategory', component: ExamsCategoryAddComponent }
        ]
      },
      {
        path: 'examSectionList', component: ExamsCourseSectionListComponent, children: [
          { path: '', component: ExamsCourseSectionListComponent },
          { path: 'addExamCourseSection', component: ExamsCourseSectionAddComponent }
        ]
      },
      {
        path: 'examCourseList', component: ExamsCourseListComponent, children: [
          { path: '', component: ExamsCourseListComponent },
          { path: 'addExamCourse', component: ExamsCourseAddComponent }
        ]
      }
    ]
  },
  {
    path: 'examMapping', component: ExamMappingComponent, children: [
      /*{ path: '', component: ExamMappingComponent },*/
      { path: 'examMappingAdd', component: ExamMappingAddComponent }]
  },
  {
    path: 'exam-sub-list', component: ExamSubItemListComponent, children: [
      /*{ path: '', component: ExamMappingComponent },*/
      { path: 'exam-sub-list-add', component: AddExamSubItemComponent },
      { path: 'exam-sub-list-edit/:id', component: EditExamSubItemComponent }
    ]
  },
  {
    path: 'daily-news', component: DailyNewsComponent, children: [
      /*{ path: '', component: ExamMappingComponent },*/
      { path: 'daily-news-add', component: AddDailyNewsComponent },
      { path: 'daily-news-edit/:id1/:id2', component: EditDailyNewsComponent }
    ]
  },
  {
    path: 'question', canActivate: [LoginGuard], children: [
      { path: '', component: QuestionComponent },
      { path: 'questionAdd', component: AddQuestionComponent }]
  },
  { path: 'about-us', component: AboutUsComponent, canActivate: [LoginGuard] },
  {
    path: 'preExam', canActivate: [LoginGuard], children: [
      { path: '', component: PreExamHomeComponent },
      { path: 'addPreExam', component: AddNewPreExamComponent },
      { path: 'addPreExamDate/:id/:categoryName', component: AddNewPreExamDateComponent },
      { path: 'editPreExamDate/:categoryID/:categoryName/:preExamID', component: EditNewPreExamDateComponent },
      { path: 'preCDACHome', component: PreExamHomeComponent }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
