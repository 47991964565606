import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ExamsService } from '../../../services/exams/exams.service';

@Component({
  selector: 'app-exams-category-add',
  templateUrl: './exams-category-add.component.html',
  styleUrls: ['./exams-category-add.component.css']
})
export class ExamsCategoryAddComponent implements OnInit {

  examCategoryForm: FormGroup;
  name: string = '';
  shortName: string = '';
  description: string = '';

  isLoadingResults = false;
  isShortNameExist = true;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private examsService: ExamsService) { }

  ngOnInit() {
    this.examCategoryForm = this.formBuilder.group({
      'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(120)]],
      'shortName': [null, [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      'description': [null, [Validators.required, Validators.minLength(4), Validators.maxLength(280)]]
      //},{ updateOn: 'blur' });
    });
    console.log(this.examCategoryForm);
  }



  /*
  isUsernameUnique(control: FormControl) {
    this.appUserService.isUserNameRegisterd(control.value).subscribe(res => {
      this.isUsernameExist = res;
      
    })
  }
  */


  onFormSubmit(form: NgForm) {
    this.isLoadingResults = true;
    this.examsService.addExamCategory(form)
      .subscribe(res => {
        //let id = res['id'];
        this.isLoadingResults = false;
        //this.router.navigate(['/product-details', id]);
        this.router.navigate(['/exams/examCategoryList']);
        //location.href = "/users";
        //this.router.navigateByUrl('users');
        //this.router.navigate([ 'users' ], { relativeTo: this.route })
      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

}
