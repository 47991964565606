import { Directive } from '@angular/core';
import { AsyncValidator, AbstractControl, Validators, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppUserService } from '../services/appUser/app-user.service';

@Directive({
  selector: '[uniqueUsername]',
  providers: [{ 
    provide: NG_ASYNC_VALIDATORS, 
    useExisting: UniqueAppUserValidationDirective, 
    multi: true }]
})
export class UniqueAppUserValidationDirective implements AsyncValidator {

  constructor(private appUserService: AppUserService) { }

  validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.appUserService.getUserByUsername(c.value).pipe(
      map(users => {
        return users && users.length > 0 ? { 'uniqueUsername': false } : null;
        //return users;
      })
    )
  }

  
}
