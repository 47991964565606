import { Component, OnInit, OnChanges } from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { AuthService } from './../auth/auth.service';
import { LoginService } from '../services/login/login.service'
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{
  username: string;
  password: string;
  
  form: FormGroup;                    // {1}
  private formSubmitAttempt: boolean; // {2}

  public error: string;
  public serverErrorMessage: string;

  constructor(private router: Router, private fb: FormBuilder,private loginService: LoginService ) { }

  ngOnInit() {
    this.form = this.fb.group({     // {5}
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
    console.log(this.form);

    //this.onChanges();
  }

  

  isFieldInvalid(field: string) { // {6}
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  /*
  onSubmit() {
    if (this.form.valid) {
      this.loginService.login(this.form.value); // {7}
    }
    this.formSubmitAttempt = true;  
    //this.router.navigate(['/']);           // {8}
  }
  */

 onSubmit() {
    if (this.form.valid) {
      //this.loginService.login(this.form.value); // {7}
      this.loginService.login(this.form.value)
      .pipe(first())
      .subscribe(
        //result => this.router.navigate(['home']),
        result => {
          this.router.navigate(['home']);
          //console.log(result);
          //this.router.navigate(['home'])
        },
        err => {
          //console.log(err.error.text);
          this.serverErrorMessage = err.error.text
          setTimeout(()=>{
            this.serverErrorMessage = "";
          },2000)
        }
        //err => this.error = 'Could not authenticate'
      );
    }
    this.formSubmitAttempt = true;  
  }

  
  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      console.log(this.form);
    });
  }

  
}
