import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ExamsService } from '../../services/exams/exams.service';

import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.css']
})
export class AddQuestionComponent implements OnInit {

  isLoadingResults = true;

  categoryList: string[];
  courseSectionList: string[];
  courseList: string[];

  questionForm: FormGroup;

  userID: any;

  testNumber = [1,2,3,4,5,6,7,8,9,10];
  languageCode = ["EN","HI"];

  saveMessage: any;
  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private router: Router, public route: ActivatedRoute, private cdr: ChangeDetectorRef) {
    this.questionForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category': [null, [Validators.required]],
      'courseSection': [null, [Validators.required]],
      'course': [null, [Validators.required]],
      'question': [null, [Validators.required]],
      'questionOptions': this.formBuilder.group({
        'option1': [null, Validators.required],
        'option2': [null, Validators.required],
        'option3': [null],
        'option4': [null],
        'option5': [null],
        'option6': [null]
      }),
      'correctAnswer': [null, [Validators.required]],
      'testNumber': [null, [Validators.required]],
      'languageCode': [null, [Validators.required]],
      'question_hint': [null, [Validators.required]],
      'answer_description': [null, [Validators.required]],
      'uploaded_by_name': [null, [Validators.required]]
      //},{ updateOn: 'blur' });
    });
  }

  ngOnInit() {
    console.log(this.questionForm);
    this.userID = localStorage.getItem('userID');
    if (this.userID == 0 || this.userID == null || this.userID == undefined) {
      this.userID = 0;
    }

    this.examsService.getExamCategoryForUserIDWise(this.userID).
      subscribe(res => {
        this.categoryList = res;
        this.isLoadingResults = false;
      },
        err => {
          this.isLoadingResults = false;
        }
      )
  }

  onCategoryChange(e) {
    this.courseSectionList = [];
    this.courseList = [];

    this.questionForm.get('courseSection').setValue(0);
    this.questionForm.get('course').setValue(0);


    this.examsService.getExamCourseSectionForUserIDWise(this.userID, e.value).
      subscribe(res => {
        console.log(res);
        this.courseSectionList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }

  onCourseSectionChange(e) {
    console.log(this.questionForm.get('category').value);
    console.log(this.questionForm.get('courseSection').value);
    //alert(this.questionForm.get('courseSection').value);
    if (this.questionForm.get('category').value != -1 && this.questionForm.get('courseSection').value != -1) {

      this.courseList = [];
      this.questionForm.get('course').setValue(0);

      this.examsService.getExamCourseForUserIDWise(this.userID, this.questionForm.get('category').value, this.questionForm.get('courseSection').value).
        subscribe(res => {
          //console.log(res);
          this.courseList = res;
          console.log(res);
          this.isLoadingResults = false;
        },
          err => {
            console.log(err);
            this.isLoadingResults = false;
          }
        )
    }
  }

  onFormSubmit(form) {


    this.isLoadingResults = true;
    var arr = Array.from(Object.keys(form.questionOptions), k => form.questionOptions[k]);
    form.questionOptions = arr.join("#~#");
    form.correctAnswer = this.questionForm.get('correctAnswer').value.join();

    this.examsService.addQuestion(form)
      .subscribe(res => {
        //let id = res['id'];
        this.questionForm.get('question').setValue("");
        this.questionForm.get('questionOptions').reset();
        this.questionForm.get('correctAnswer').setValue(0);
        this.isLoadingResults = false;
        if (res > 0) {
          this.saveMessage = "Question successfully saved."
          setTimeout(() => {
            this.saveMessage = "";
          }, 2000);
        }
        //this.router.navigate(['/product-details', id]);
        //this.router.navigate(['/exams/examCourseList']);
        //location.href = "/users";
        //this.router.navigateByUrl('users');
        //this.router.navigate([ 'users' ], { relativeTo: this.route })
      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }
}
