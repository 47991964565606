import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
      'Access-Control-Allow-Origin': '*'
    }
  )
};

@Injectable()
export class UsersService {

  constructor(private http: HttpClient) { }

  getUserList(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(environment.apiUrl + '/users', httpOptions)
      .pipe(
        tap(res => console.log('fetched usres')),
        catchError(this.handleError('getUserList', []))
      );
  }

  getUserById(id: number): Observable<UserModel> {
    const url = `${environment.apiUrl + '/users'}/${id}`;
    return this.http.get<UserModel>(url).pipe(
      tap(_ => console.log(`fetched user id=${id}`)),
      catchError(this.handleError<UserModel>(`getUserById id=${id}`))
    );
  }

  addUser(user): Observable<UserModel> {
    return this.http.post<UserModel>(environment.apiUrl + '/users', user, httpOptions).pipe(
      tap((user: UserModel) => console.log(`added user w/ id=${user.id}`)),
      catchError(this.handleError<UserModel>('addUser'))
    );
  }

  updateUser(id, user): Observable<any> {
    const url = `${environment.apiUrl + '/users'}/${id}`;
    return this.http.put(url, user, httpOptions).pipe(
      tap(_ => console.log(`updated user id=${id}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }

  deleteUser(id): Observable<UserModel> {
    const url = `${environment.apiUrl + '/users'}/${id}`;
    return this.http.delete<UserModel>(url, httpOptions).pipe(
      tap(_ => console.log(`deleted user id=${id}`)),
      catchError(this.handleError<UserModel>('deleteUser'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  
}
