import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ExamsService } from '../services/exams/exams.service';
import { AppUserService } from '../services/appUser/app-user.service';

@Component({
  selector: 'app-exam-sub-item-list',
  templateUrl: './exam-sub-item-list.component.html',
  styleUrls: ['./exam-sub-item-list.component.css']
})
export class ExamSubItemListComponent implements OnInit {

  displayedColumns: string[] = ['srno',
    'sub_item_name',
    //'sub_item_name_short',
    'no_of_question',
    'exam_time',
    'mark_for_correct',
    'mark_for_wrong',
    'test_number',
    'is_active',
    'map_menu',
    'remove'

  ];

  alertMessage: any = "";

  examMappingForm: FormGroup;
  userID: any;

  isLoadingResults = true;

  categoryList: string[];
  courseSectionList: string[];
  courseList: string[];

  data: any;
  public array: any;
  public pageSize = 20;
  public currentPage = 0;
  public totalSize = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private router: Router,
    private appUserService: AppUserService, public route: ActivatedRoute,private changeDetectorRefs: ChangeDetectorRef,
    private cdr: ChangeDetectorRef) {
    this.examMappingForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'category': [null, [Validators.required]],
      'courseSection': [null],
      'course': [null]
      //},{ updateOn: 'blur' });
    });
  }

  ngOnInit() {

    this.userID = localStorage.getItem('userID');
    if (this.userID == 0 || this.userID == null || this.userID == undefined) {
      this.userID = 0;
    }
    console.log(this.userID);

    this.examsService.getExamCategoryForUserIDWise(this.userID).
      subscribe(res => {
        console.log(res);
        this.categoryList = res;
        this.isLoadingResults = false;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }



  onFormSubmit(form) {
    console.log(form);
    this.getExamSubList(form);
  }


  getExamSubList(form) {
    this.examsService.getExamSubItem(form)
      .subscribe(res => {
        this.data = new MatTableDataSource<Element>(res);

        this.changeDetectorRefs.detectChanges();

        this.data.paginator = this.paginator;
        this.array = res;
        this.totalSize = this.array.length;
        this.iterator();
        //this.data = res;
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }


  onCategoryChange(e) {
    this.courseSectionList = [];
    this.courseList = [];

    this.examMappingForm.get('courseSection').setValue(0);
    this.examMappingForm.get('course').setValue(0);


    this.examsService.getExamCourseSectionForUserIDWise(this.userID, e.value).
      subscribe(res => {
        console.log(res);
        this.courseSectionList = res;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }


  onCourseSectionChange(e) {
    console.log(this.examMappingForm.get('category').value);
    console.log(this.examMappingForm.get('courseSection').value);
    //alert(this.examMappingForm.get('courseSection').value);
    if (this.examMappingForm.get('category').value != -1 && this.examMappingForm.get('courseSection').value != -1) {

      this.courseList = [];
      this.examMappingForm.get('course').setValue(0);

      this.examsService.getExamCourseForUserIDWise(this.userID, this.examMappingForm.get('category').value, this.examMappingForm.get('courseSection').value).
        subscribe(res => {
          //console.log(res);
          this.courseList = res;
          console.log(res);
          this.isLoadingResults = false;
        },
          err => {
            console.log(err);
            this.isLoadingResults = false;
          }
        )
    }
  }

  editSubExamItem(id) {
    this.router.navigate(['/exam-sub-list/exam-sub-list-edit', id]);
   }

  deleteSubExamItem(id) {

    if (confirm("Are you sure to delete ?")) {
      this.examsService.deleteExamSubItem(id)
        .subscribe(res => {

          if (res) {
            this.alertMessage = res['message'];
            alert(res['message']);
            setTimeout(() => {
              this.alertMessage = "";
              this.reloadComponent();
            }, 2000);
          }

          console.log(res);
        }, (err) => {
          console.log(err);

          this.alertMessage = err;
          setTimeout(() => {
            this.alertMessage = "";
          }, 2000);

        })
    }

  }

  clearSearch() {

  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/exam-sub-list']);
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(start, end);
    this.data = part;
  }

}