import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { AppUser } from '../../models/app-user.model';
import { environment } from '../../../environments/environment';
import { HttpClientModule } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
      'Access-Control-Allow-Origin': '*'
    }
  )
};

@Injectable()
export class AppUserService {

  constructor(private http: HttpClient) { }

  getUserList(): Observable<any[]> {
    return this.http.get<AppUser[]>(environment.apiUrl_for_php + '/app-users')
      .pipe(
        tap(res => console.log('fetched usres')),
        catchError(this.handleError('getUserList', []))
      );
  }

  getUserById(id: number): Observable<AppUser> {
    const url = `${environment.apiUrl + '/users'}/${id}`;
    return this.http.get<AppUser>(url).pipe(
      tap(_ => console.log(`fetched user id=${id}`)),
      catchError(this.handleError<AppUser>(`getUserById id=${id}`))
    );
  }

  addUser(user): Observable<AppUser> {
    return this.http.post<AppUser>(environment.apiUrl_for_php + '/app-users', user).pipe(
      tap((user: AppUser) => console.log(`added user w/ id=${user.id}`)),
      catchError(this.handleError<AppUser>('addUser'))
    );
  }

  updateUser(id, user): Observable<any> {
    const url = `${environment.apiUrl + '/users'}/${id}`;
    return this.http.put(url, user, httpOptions).pipe(
      tap(_ => console.log(`updated user id=${id}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }

  deleteUser(id): Observable<AppUser> {
    const url = `${environment.apiUrl + '/users'}/${id}`;
    return this.http.delete<AppUser>(url, httpOptions).pipe(
      tap(_ => console.log(`deleted user id=${id}`)),
      catchError(this.handleError<AppUser>('deleteUser'))
    );
  }

  isUserNameRegisterd(username: any) {
    const url = `${environment.apiUrl_for_php + '/username-exist'}`;
    return this.http.post(url, JSON.stringify({ username: username }), httpOptions).pipe(
      map((response: Response) => {
        //response.json()
        return response;
      }),
      catchError(this.handleError<any>('username-exist'))
    );
  }

  getUserByUsername(username: string){
    return this.http.get<any>(environment.apiUrl_for_php + '/username-exist?id='+ username)

    // const url = `${environment.apiUrl_for_php + '/username-exist'}/${username}`;
    // return this.http.get<any[]>(url);
  }
  

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
