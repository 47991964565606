import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ExamsService } from '../../services/exams/exams.service';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-pre-exam-home',
  templateUrl: './pre-exam-home.component.html',
  styleUrls: ['./pre-exam-home.component.css']
})
export class PreExamHomeComponent implements OnInit {

  @ViewChild('ref') ref;

  isLoadingResults = true;

  isDisabledTrue = true;
  saveMessage: any;

  preExamList: string[];
  preExamDateList: string[];
  sectionList: string[];

  courseList: string[];

  questionLevel: string[];

  questionsList = [];
  selectedQuestionList = [];
  selectedQuestionPreviousArr = [];

  selectedServerQuestionIds: any;
  selectedServerQuestionIdsArr: any = [];

  submitSelectedQuestionIds: any = "";

  tmpArr = [];
  uniqueArray = [];
  uniquequetionsIds = "";
  examId_: any;
  preExamDateId_: any;
  examCategoryId_: any;
  examSectionId_: any;
  examCourseId_: any;
  examQuestionNumber_: any;

  questionForm: FormGroup;

  userID: any;

  form: any = {};

  testNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  maxQuestionCount = 0;

  examCourseQuestionMaxCount = 0;

  totalQuestionAssignForExamDateWise = 0;

  isDeletedAction = false;

  deletedArrForSubmitToServe = [];

  constructor(private formBuilder: FormBuilder, private examsService: ExamsService, private router: Router, public route: ActivatedRoute, private cdr: ChangeDetectorRef) {
    this.questionForm = this.formBuilder.group({
      //'name': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]],
      'preExamList': [null, [Validators.required]],
      'preExamDateList': [null, [Validators.required]],
      'sectionName': [null, [Validators.required]],
      'courseName': [null, [Validators.required]],
      'testNumber': [null, [Validators.required]],
      'isUnique': [null, null],
      'isDelete': [null, null]

      //},{ updateOn: 'blur' });
    });
  }
  ngOnInit() {
    console.log(this.questionForm);
    this.userID = localStorage.getItem('userID');
    if (this.userID == 0 || this.userID == null || this.userID == undefined) {
      this.userID = 0;
    }

    this.examsService.getPreExamList(this.userID).
      subscribe(res => {
        this.preExamList = res;
        this.isLoadingResults = false;
      },
        err => {
          this.isLoadingResults = false;
        }
      )
  }

  onFormSubmit(form) {

    if (!this.isDeletedAction) {


      this.isLoadingResults = true;

      //console.log(form);
      //console.log(this.questionsList);

      //current Selected Question arr
      //console.log(this.selectedQuestionList);

      //All Ready Assigned server Ids fixed all time (come form server)
      //console.log(this.selectedServerQuestionIds);

      //All Ready Assigned arr
      //console.log(this.selectedQuestionPreviousArr);

      this.selectedQuestionList.forEach(element => {
        if (this.submitSelectedQuestionIds != "") {
          this.submitSelectedQuestionIds = this.submitSelectedQuestionIds + "," + element.id;
        }
        else {
          this.submitSelectedQuestionIds = element.id;
        }

      });

      this.selectedQuestionPreviousArr.forEach(element => {
        if (this.submitSelectedQuestionIds != "") {
          this.submitSelectedQuestionIds = this.submitSelectedQuestionIds + "," + element.id;
        }
        else {
          this.submitSelectedQuestionIds = element.id;
        }

      });

      //console.log(this.submitSelectedQuestionIds);

      var validIds = /[,\-]/.test(this.submitSelectedQuestionIds);

      if (this.submitSelectedQuestionIds != "" && this.submitSelectedQuestionIds != undefined && validIds == true) {
        this.tmpArr = this.submitSelectedQuestionIds.split(",");
        this.uniqueArray = this.tmpArr.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        })

        this.uniquequetionsIds = this.uniqueArray.join();
      }
      else {
        //
        this.uniquequetionsIds = this.submitSelectedQuestionIds;
      }

      console.log(this.uniquequetionsIds);





      //submit selected question to server
      var action = {
        "submitSelectedQuestionIds": this.uniquequetionsIds,
        "examID": this.examId_
      }


      this.examsService.addPreExamQuestionPaper(action, this.preExamDateId_)
        .subscribe(res => {

          this.questionForm.reset();
          this.uniquequetionsIds = "";
          this.submitSelectedQuestionIds = "";

          this.selectedServerQuestionIds = "";

          this.preExamDateList = [];
          this.sectionList = [];
          this.courseList = [];
          this.questionsList = [];
          this.selectedQuestionList = [];
          this.selectedQuestionPreviousArr = [];


          //this.questionForm.get('questionOptions').reset();
          //this.questionForm.get('correctAnswer').setValue(0);

          this.isLoadingResults = false;
          if (res > 0) {
            this.saveMessage = "Paper set successfully."
            setTimeout(() => {
              this.saveMessage = "";
            }, 4000);
          }
          else {
            this.saveMessage = res;
          }
          //this.router.navigate(['/product-details', id]);
          //this.router.navigate(['/exams/examCourseList']);
          //location.href = "/users";
          //this.router.navigateByUrl('users');
          //this.router.navigate([ 'users' ], { relativeTo: this.route })
        }, (err) => {
          console.log(err);
          this.saveMessage = err;
          this.isLoadingResults = false;

          this.questionForm.reset();
          this.uniquequetionsIds = "";
          this.submitSelectedQuestionIds = "";

          this.selectedServerQuestionIds = "";

          this.preExamDateList = [];
          this.sectionList = [];
          this.courseList = [];
          this.questionsList = [];
          this.selectedQuestionList = [];
          this.selectedQuestionPreviousArr = [];

        });

    }

    else {

      alert("delete action");
    }
  }


  onExamListChange(e) {
    this.preExamDateList = [];
    this.sectionList = [];
    this.courseList = [];
    this.questionForm.get('preExamDateList').setValue(0);
    this.questionForm.get('sectionName').setValue(0);

    this.examId_ = e.value.id;
    this.examCategoryId_ = e.value.category_id;

    this.questionsList = [];
    this.selectedQuestionList = [];
    this.selectedQuestionPreviousArr = [];

    this.resetAllData();

    this.examsService.getPreExamDateList(this.userID, e.value.id).
      subscribe(res => {
        console.log(res);
        if (res == null) {
          alert("No Pre Exam available");
        }
        else {
          this.preExamDateList = res;
        }
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }

  onPreExamDateChange(e) {
    console.log(e);
    this.sectionList = [];
    this.courseList = [];
    this.questionForm.get('sectionName').setValue(0);

    this.questionsList = [];
    this.selectedQuestionList = [];
    this.selectedQuestionPreviousArr = [];

    this.resetAllData();

    this.preExamDateId_ = e.value.id;
    this.selectedServerQuestionIds = e.value.question_ids;

    var valid = /[,\-]/.test(this.selectedServerQuestionIds);

    if (this.selectedServerQuestionIds != null && this.selectedServerQuestionIds != undefined && this.selectedServerQuestionIds != 0 && valid == true) {
      this.selectedServerQuestionIdsArr = this.selectedServerQuestionIds.split(',');
    }
    else {
      this.selectedServerQuestionIdsArr.push(this.selectedServerQuestionIds);
    }

    if (this.selectedServerQuestionIdsArr.length > 0 && this.selectedServerQuestionIdsArr[0] == "") {
      this.totalQuestionAssignForExamDateWise = this.selectedServerQuestionIdsArr.length - 1;
    }
    else {
      this.totalQuestionAssignForExamDateWise = this.selectedServerQuestionIdsArr.length
    }


    console.log(this.selectedServerQuestionIdsArr);
    this.examsService.getExamCourseSectionForUserIDWise(this.userID, e.value.category_id).
      subscribe(res => {
        console.log(res);
        if (res.toString() == 'error' || res.toString() == '-1') {
          this.sectionList = [];
        }
        else {
          this.sectionList = res;
        }
        this.isLoadingResults = false;
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }

  onPreExamSectionChange(e) {
    console.log(e);
    this.courseList = [];
    this.examSectionId_ = e.value.id;

    this.questionsList = [];
    this.selectedQuestionList = [];
    this.selectedQuestionPreviousArr = [];

    this.examsService.getPreExamCourseWithQuestionCount(this.userID, this.examId_, e.value.id).
      subscribe(res => {
        console.log(res);
        this.courseList = res;
        this.maxQuestionCount = res['question_count'];
      },
        err => {
          console.log(err);
          this.isLoadingResults = false;
        }
      )
  }

  onPreExamCourseChange(e) {
    console.log(e);
    this.examCourseId_ = e.value.course_id;
    this.examCourseQuestionMaxCount = e.value.question_count;
    this.questionForm.get('testNumber').setValue(0);

    this.questionsList = [];
    this.selectedQuestionList = [];
    this.selectedQuestionPreviousArr = [];

  }

  onPreExamTestQuestionNumberChange(e) {
    console.log(e);

    this.questionsList = [];

    //this.selectedQuestionList = [];
    //this.selectedQuestionPreviousArr = [];

    this.examQuestionNumber_ = e.value;

    this.getQuestionListWithFilters();
  }

  getQuestionListWithFilters() {
    this.form.category = this.examCategoryId_;
    this.form.courseSection = this.examSectionId_;
    this.form.course = this.examCourseId_;
    this.form.testNumber = this.examQuestionNumber_;

    if (this.questionForm.controls['isUnique'].value) {
      this.form.isUnique = true;
    }
    else {
      this.form.isUnique = false
    }
    //console.log(this.questionForm.controls['isUnique'].value);
    this.examsService.getQuestionListWithFiltersWithQuestionTestNumber(this.userID, this.examId_, this.form)
      .subscribe(res => {
        console.log(res);
        this.questionsList = res;

        if (this.selectedServerQuestionIds != null && this.selectedServerQuestionIds != undefined && this.selectedServerQuestionIds != 0) {
          if (this.questionsList.length > 0) {
            this.questionsList = this.questionListRemoveAllReadyAssignedQuestion(this.questionsList);
            console.log(this.questionsList);
          }
          else {
            //get all ready assigned server question
            this.examsService.getAllReadyAssignedQuestionList(this.selectedServerQuestionIds).subscribe(res => {
              console.log(res);
              //this.selectedQuestionPreviousArr = res;
              this.selectedQuestionPreviousArr = [];
              res.forEach(element => {
                if (element.exam_course_id == this.examCourseId_) {
                  this.selectedQuestionPreviousArr.push(element);
                }
              });
            },
              err => {
                console.log(err);
              })
          }
        }

        if (this.questionForm.controls['isUnique'].value && this.selectedServerQuestionIds != 'null') {
          //get all ready assigned server question
          this.examsService.getAllReadyAssignedQuestionList(this.selectedServerQuestionIds).subscribe(res => {

            console.log(this.examCourseId_);
            console.log(res);
            this.selectedQuestionPreviousArr = [];
            if (res != null && res != undefined) {
              res.forEach(element => {
                if (element.exam_course_id == this.examCourseId_) {
                  this.selectedQuestionPreviousArr.push(element);
                }
              });
            }
          },
            err => {
              console.log(err);
            })
        }
        //this.todo = res;
        //this.data = res;
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

  questionListRemoveAllReadyAssignedQuestion(questionList) {
    for (let i = 0; this.selectedServerQuestionIdsArr.length > i; i++) {
      for (let j = 0; questionList.length > j; j++) {
        if (questionList[j].id == this.selectedServerQuestionIdsArr[i]) {

          if (this.selectedQuestionPreviousArr.length > 0) {
            if (!this.questionExists(this.selectedQuestionPreviousArr, questionList[j].id)) {
              this.selectedQuestionPreviousArr.push(questionList[j]);
            }
          }
          else if (this.selectedQuestionPreviousArr.length == 0) {
            this.selectedQuestionPreviousArr.push(questionList[j]);
          }

          questionList.splice(j, 1);


          /*
          if(this.selectedQuestionPreviousArr.length > 0 && !this.selectedQuestionPreviousArr.includes(questionList[j])){
            this.selectedQuestionPreviousArr.push(questionList[j]);
          }
          
          */
        }
      }
    }

    return questionList;
    //console.log(questionList);
  }


  questionExists(arr, qiestionID) {
    return arr.some(function (el) {
      return el.id === qiestionID;
    });
  }


  /*
  questionListRemoveAllReadyAssignedQuestion(questionList) {
    var filtered = questionList.filter(function (value, index, arr) {
      if (this.selectedServerQuestionIdsArr != null && this.selectedServerQuestionIdsArr != undefined && this.selectedServerQuestionIdsArr != 0 && this.selectedServerQuestionIdsArr.length > 0) {
        for (let i = 0; this.selectedServerQuestionIdsArr.length > 0; i++) {
          if (value.id == this.selectedServerQuestionIdsArr[i]) {
            return false;
          }
          continue;
        }
      }
      console.log(filtered);
    });
  }
  */

  drop(event: CdkDragDrop<string[]>) {

    //console.log("selectedQuestionList = " +this.selectedQuestionList.length);
    //console.log("selectedQuestionList = " +this.selectedQuestionPreviousArr.length);

    //console.log(this.selectedQuestionList.length + this.selectedQuestionPreviousArr.length)

    if (event.previousContainer.id == "cdk-drop-list-0" && event.container.id == "cdk-drop-list-2" && event.item['_dir'].value == "ltr") {
      console.log("this isn't happening today");
      return;
    }

    if (event.previousContainer.id == "cdk-drop-list-1" && event.container.id == "cdk-drop-list-2" && event.item['_dir'].value == "ltr") {
      console.log("this isn't happening today");
      return;
    }



    this.submitSelectedQuestionIds = "";
    if (event.previousContainer === event.container) {

      //console.log(this.questionsList.length);
      //console.log(this.selectedQuestionList.length);
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      //console.log(this.questionsList.length);
      //console.log(this.selectedQuestionList.length);
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  isDelete(event) {
    event.preventDefault();
    this.ref._checked = !this.ref._checked;
    console.log(this.ref._checked);
    if (this.ref._checked) {
      this.isDeletedAction = true;
    }
    else {
      this.isDeletedAction = false;
    }
  }

  deleteQuestion(item) {
    //console.log(item);
    item.forEach(element => {
      console.log(element.value);
      this.deletedArrForSubmitToServe.push(element.value);
    });
    //submit selected question to server
    var action = {
      "submitSelectedDeleteQuestionIds": this.deletedArrForSubmitToServe,
      "examID": this.examId_
    }

    this.examsService.deletePreExamQuestionPaper(action, this.preExamDateId_)
      .subscribe(res => {

        this.questionForm.reset();
        this.uniquequetionsIds = "";
        this.submitSelectedQuestionIds = "";

        this.selectedServerQuestionIds = "";

        this.preExamDateList = [];
        this.sectionList = [];
        this.courseList = [];
        this.questionsList = [];
        this.selectedQuestionList = [];
        this.selectedQuestionPreviousArr = [];

        this.deletedArrForSubmitToServe = [];

        this.isDeletedAction = false;

        this.resetAllData();

        this.isLoadingResults = false;
        if (res > 0) {
          this.saveMessage = "question removed successfully."
          setTimeout(() => {
            this.saveMessage = "";
          }, 4000);
        }
        else {
          this.saveMessage = res;
        }
      }, (err) => {
        console.log(err);
        this.saveMessage = err;
        this.isLoadingResults = false;

        this.questionForm.reset();
        this.uniquequetionsIds = "";
        this.submitSelectedQuestionIds = "";

        this.selectedServerQuestionIds = "";

        this.preExamDateList = [];
        this.sectionList = [];
        this.courseList = [];
        this.questionsList = [];
        this.selectedQuestionList = [];
        this.selectedQuestionPreviousArr = [];

        this.deletedArrForSubmitToServe = [];

        this.isDeletedAction = false;

        this.resetAllData();

      });
  }
  resetAllData() {

    this.totalQuestionAssignForExamDateWise = 0;
    this.uniquequetionsIds = "";
    this.submitSelectedQuestionIds = "";
    this.selectedServerQuestionIds = "";
    this.selectedQuestionList = [];
    this.selectedQuestionPreviousArr = [];
    this.selectedServerQuestionIdsArr = [];

  }

  onChange(event, index, item, abc) {

    //item.checked = !item.checked;

    //this.lastAction = 'index: ' + index + ', label: ' + item.label + ', checked: ' + item.checked;

    console.log(index, event, item, abc);

  }
}
