import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-examslist',
  templateUrl: './examslist.component.html',
  styleUrls: ['./examslist.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class ExamslistComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
